export default {
  id: {
    type: Number,
    visibility: false,
  },
  name: {
    label: 'Nombre',
    type: String,
  },
  uniqueId: {
    label: 'Identificador',
    type: String,
  },
  servidor: {
    label: 'Servidor',
    type: String,
    options: [
      { id: 'legacy', name: 'Legacy Culiacan' },
      { id: 'culiacan', name: 'Culiacan' },
    ],
  },
  city: {
    label: 'Municipio',
    type: String,
    options: [
      { id: 'AHOME', name: 'AHOME' },
      { id: 'EL FUERTE', name: 'EL FUERTE' },
      { id: 'CHOIX', name: 'CHOIX' },
      { id: 'GUASAVE', name: 'GUASAVE' },
      { id: 'SINALOA', name: 'SINALOA' },
      { id: 'ANGOSTURA', name: 'ANGOSTURA' },
      { id: 'SALVADOR ALVARADO', name: 'SALVADOR ALVARADO' },
      { id: 'MOCORITO', name: 'MOCORITO' },
      { id: 'BADIRAGUATO', name: 'BADIRAGUATO' },
      { id: 'NAVOLATO', name: 'NAVOLATO' },
      { id: 'CULIACAN', name: 'CULIACAN' },
      { id: 'ELOTA', name: 'ELOTA' },
      { id: 'COSALA', name: 'COSALA' },
      { id: 'SAN IGNACIO', name: 'SAN IGNACIO' },
      { id: 'MAZATLAN', name: 'MAZATLAN' },
      { id: 'CONCORDIA', name: 'CONCORDIA' },
      { id: 'ROSARIO', name: 'ROSARIO' },
      { id: 'ESCUINAPA', name: 'ESCUINAPA' },
    ],
  },
  address: {
    label: 'Dirección',
    type: String,
    textarea: true,
  },
  _location: {
    label: 'Ubicación',
    type: String,
    location: true,
    fields: ['latitude', 'longitude'],
  },
  longitude: {
    label: 'Longitud',
    type: Number,
    visibility: false,
  },
  latitude: {
    label: 'Latitud',
    type: Number,
    visibility: false,
  },
  contact: {
    label: 'Contacto',
    type: String,
  },
  groupId: {
    label: 'Grupo',
    type: Number,
    optional: true,
    model: 'groups',
    module: 'cameras',
    autocomplete: false,
  },
  disabled: {
    label: 'Deshabilitado',
    type: Boolean,
    optional: true,
  },
  attributes: {
    label: 'Camaras',
    type: Array,
    title: (item) => {
      return item.name;
    },
    onChange: (val, field) => {
      field.attributes.value.forEach((attr, i) => {
        attr.name = 'dev' + i;
      });
    },
    optional: true,
  },
  'attributes.$': {
    type: {
      name: {
        label: 'Nombre',
        type: String,
        visibility: false,
      },
      ip: {
        label: 'Dirección IP',
        type: String,
      },
      usuario: {
        label: 'Usuario',
        type: String,
      },
      password: {
        label: 'Password',
        type: String,
      },
      stream: {
        label: 'Stream',
        type: String,
      },
      isPtz: {
        label: 'Es PTZ',
        type: Boolean,
      },
    },
  },
};
