<template>
  <v-card class="mx-auto elevation-0" height="100%">
    <v-toolbar class="kalan-titles elevation-0" color="primary--text">
      <v-toolbar-title>
        <span>{{ $vuetify.lang.t(`$vuetify.${model}.plural_name`) }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div style="margin-right: 20px;">
        <v-text-field flat label="Buscar" hide-details v-model="search" append-icon="mdi-magnify"></v-text-field>
      </div>
      <actions-button :item="{}" :actions="actions"></actions-button>
    </v-toolbar>
    <kalan-list :loading="loading" :getTitle="getTitle" :getSubTitle="getSubTitle" :getExtra="getExtra" :items="items"
      :search="search" :actions="listActions">
    </kalan-list>
  </v-card>
</template>

<script>
import ApiService from '@/util/api.service'
import Cameras from '@/models/cameras.model'
import Crud from '@/util/crud.service'

export default {
  props: {
    model: {
      default: 'cameras'
    },
  },
  components: {
    'kalan-list': () => import('@/components/modulo/List.vue'),
    'actions-button': () => import('@/components/modulo/Actions.vue')
  },
  data() {
    return {
      items: [],
      groups: {},
      loading: true,
      search: '',
      attributeFields: [
        'servidor',
      ],
      actions: [{
        help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.create`),
        icon: 'mdi-plus',
        color: 'secondary',
        action: (item) => {
          Crud.create({
            item,
            schema: Cameras,
            model: this.model,
            preSave: this.preSave
          }).then(this.load)
        }
      }],
      listActions: [{
        help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.update`),
        icon: 'mdi-pencil',
        color: 'accent',
        action: (item) => {
          Crud.update({
            item,
            schema: Cameras,
            model: this.model,
            title: this.getTitle(item),
            preSave: this.preSave
          }).then(this.load)
        }
      }, {
        help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.read`),
        icon: 'mdi-eye',
        color: 'mattBlack',
        action: (item) => {
          Crud.read({
            item,
            schema: Cameras,
            model: this.model,
            title: this.getTitle(item)
          })
        }
      }, {
        help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.delete`),
        icon: 'mdi-delete',
        color: 'red',
        action: (item) => {
          Crud.delete({
            id: item.id,
            model: this.model,
            title: this.getTitle(item)
          }).then(this.load)
        }
      }],
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    getTitle: (item) => item.name,
    getSubTitle(item) {
      let name = 'Desconocido'
      if (this.groups && this.groups[item.groupId] && this.groups[item.groupId].name) {
        name = this.groups[item.groupId].name
      }
      return `${item.uniqueId} | ${name} `
    },
    getExtra: () => null,
    preSave(item) {
      const it = {
        ...item,
        attributes: (item.attributes || []).reduce(
          (acc, val) => ({ ...acc, [val.name]: btoa(JSON.stringify(val)) }),
          {}
        )
      }
      this.attributeFields.forEach((field) => {
        it.attributes[field] = it[field]
        delete it[field]
      })
      return it
    },
    load() {
      this.loading = true
      ApiService({
        url: '/groups',
        method: 'get',
      }).then((resp) => {
        const grupos = resp.reduce((obj, item) => {
          return {
            ...obj,
            [item.id]: item
          }
        }, {})
        this.groups = grupos
      })
      ApiService({
        url: `/${this.model}`,
        method: 'get',
      }).then((resp) => {
        this.items = resp.map((it) => {
          try {
            this.attributeFields.forEach((field) => {
              it[field] = it.attributes[field]
              delete it.attributes[field]
            })
          } catch (err) {
            console.log('aqui', err)
          }
          return it
        })
        this.items = resp.map((item) => {
          if (item.attributes) {
            return {
              ...item,
              attributes: Object.keys(item.attributes).map(
                (key) => {
                  let ret = { name: key }
                  try {
                    ret = JSON.parse(atob(item.attributes[key]))
                    ret.name = key
                  } catch (err) {
                    //
                  }
                  return ret
                }
              )
            }
          }
          return item
        })
        this.loading = false
      }).catch((err) => {
        console.trace(err)
        this.loading = false
      })
    },
  }
}
</script>
